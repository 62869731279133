import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, BehaviorSubject, throwError, from } from 'rxjs';
import { RequestType, UtilsService } from './../utils/utils.service';

// import { TimeAgo } from 'javascript-time-ago';
import { User } from '../../models/user';
import { environment as ENV } from '../../../environments/environment';
import { StorageService } from '../storage/storage.service';

// Load locale-specific relative date/time formatting rules and labels.
// import en from 'javascript-time-ago/locale/en';
// TimeAgo.addLocale(en);

@Injectable({
  providedIn: 'root',
})


export class UserService {

  profile: User = null;

  // timeAgo = new TimeAgo('en-US');
  authSubject  = new BehaviorSubject(false);

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private utilsService: UtilsService) { }

  private headers = new HttpHeaders();


  // GET Request to /api/users/current
  // gets the details of the current user
  getProfile(): Promise<User> {
    return this.utilsService.makeGetRequest(`users/me`)
  }


  getProfileWithPeople(): Promise<User> {
    return this.utilsService.makeGetRequest(`users/me?populate=people`)
  }
  getLocalProfile(){
    return this.profile;
  }

  loadProfile():Promise<User>{
    return new Promise(resolve=>{

      this.getProfile().then((user:User) => {
        this.profile = user;
        resolve(user)
      });
    })
  }

  getCurrentUser(): Promise<User> {
    return this.getProfile()
  }

  // PUT Request to /api/users/current
  // updates the current user's profile/information
  updateUser(user: User|any): Observable<User> {
    return this.httpClient.put<User>(`${this.utilsService.getApi()}/users/current`, user);
  }

  update(user: User|any): Observable<User> {
    return this.updateUser(user)
  }

  // GET Request to /api/users/current/avatar
  // gets the current user's profile image
  getAvatar(): Observable<any> {
    return this.httpClient.get<any>(`${this.utilsService.getApi()}/users/current/avatar`);
  }

  // PATCH Request to /api/users/current/avatar
  // changes the current user's profile image
  setAvatar(img): Observable<any> {
    return this.httpClient.patch(`${this.utilsService.getApi()}/users/current/avatar`, img);
  }

  resetPassword(creds){
    return this.utilsService.makeRequest(`auth/reset-password`, RequestType.post, creds);
  }
}
