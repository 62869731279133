import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AppAlertService {

  constructor(
    private alertController: AlertController,
    private toastCtrl: ToastController,
    public loadingController: LoadingController,
    private modalCOntroller: ModalController) { }



  //Creating Block Alert Component

  async showBlockedAlert() {
    const blockAlert = await this.alertController.create({
      header: 'User Blocked',
      subHeader: ' You Have Been Permanently Blocked From Using this Application',
      buttons: [' Dismiss ']
    });
    await blockAlert.present();
    let result = await blockAlert.onDidDismiss();
    console.log(result);
  }


//Creating Suspended alert component

  async showSuspendedAlert() {
    const suspendAlert = await this.alertController.create({
      header: 'User Suspended',
      subHeader: ' You Have Been Temporarily Suspended From Using this Application',
      buttons: [' Dismiss ']
    });


    await suspendAlert.present();
    let result = await suspendAlert.onDidDismiss();
    console.log(result);
  }

  async showDebugAlert(header, message, subHeader = null, buttons = ['OK']) {
    let alert = await this.alertController.create({
      cssClass: 'app-debug-alert',
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: buttons
    });

    alert.present();

    return alert;
  }

  async showGeneralAlert(header, message=null, subHeader = null, buttons = ['OK']) {
    let alert = await this.alertController.create({
      cssClass: 'app-alert',
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: buttons
    });

    alert.present();

    return alert;
  }

  async showGeneralToast(message, position:any="top") {
    const genAlert = await this.toastCtrl.create({
      message: message,
      duration: 5000,
      position: position,
      mode: 'ios',
      buttons:['Close']
    });
    genAlert.present();
  }

  async showSuccessAlert(message, position:any="bottom") {
    const genAlert = await this.toastCtrl.create({
      message: message,
      duration: 5000,
      position: position,
      mode: 'ios',
      color: 'success',
      buttons:['Close']
    });
    genAlert.present();
  }

  async showErrorAlert(message, position:any="bottom", duration= 10000) {
    const genAlert = await this.toastCtrl.create({
      message: message,
      duration: duration,
      position: position,
      mode: 'ios',
      color: 'danger',
      buttons:['Close']
    });
    genAlert.present();

    return genAlert;
  }

  async showLoader(){
    const loading = await this.loadingController.create();
    await loading.present()
    return loading;
  }

}
